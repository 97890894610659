.page-content img.wp-smiley,
.entry-content img.wp-smiley,
.comment-content img.wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

@import "captions";
@import "galleries";