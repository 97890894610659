body {
    padding-top: 90px;

    &.mobile-menu-show {
        overflow-y: hidden;
    }
}

a {
    transition: all .2s;
}

img {
    max-width: 100%;
    height: auto;
}

//header helpers
h1 {
    color: $red;

}

.lead {
    margin-bottom: $line-height-computed;
    font-size: floor(($font-size-base * 1.15));
    font-weight: 300;
    line-height: 1.4;

    @media (min-width: $screen-sm-min) {
        font-size: ($font-size-base * 1.25);
    }
}


//color classes
.white {
    color: $white;
}
.blue {
    color: $blue;
}
.red {
    color: $red;

    a {
        color: $red;
    }
}
.gray {
    color: $gray;
}

:focus {
    outline: none !important;
}


/* ---- Header#masthead ---- */

$header-height: 90px;

header#masthead {
    height: $header-height;
    background: $blue;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;

    #logo {

    }
    .site-branding {
        line-height: $header-height;
        position: absolute;

        a {
            text-decoration: none;
        }
    }
    .site-title {
        margin-bottom: 0;
    }
    .site-description {
        font-size: 10px;
        text-align: center;
    }
    #site-navigation {
        line-height: $header-height;
        float: right;
    }
    //Header#masthead social icons
    .social-icons {
        margin-left: 10px;
        line-height: $header-height;

        a {
            text-decoration: none;

            i:last-child {
                color: $blue;
            }
        }
    }
}


/* ---- Top-Section ---- */

#top-section {
    background: url('../images/background-traffic.jpg') center top/cover no-repeat;
    padding: 50px;

    h3{
        margin: 0;
    }

    @media screen and (max-width: $screen-sm) {
        padding: 15px 0;

        * {
            font-size: $font-size-large;
        }
    }
}

#content.site-content {
    margin-top: 25px;
    margin-bottom: 25px;
}

/* ---- Posts ---- */

.wp-post-image {
    border: 2px solid white;
    border-radius: $border-radius-base;
}

.nav-previous,.nav-next {
    margin-top: 25px;
}

.read-more {
    color: $red;

    &:hover {
        color: $red;
    }
}


/* ---- Footer#colophon ---- */

$footer-height: 50px;
footer#colophon {
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    color: $gray-lighter;
    margin-bottom: 25px;
    height: $footer-height;

    .site-info {
        margin-left: 15px;
        height: $footer-height;

        * {
            color: $gray-lighter;
        }

        span:first-child {
            font-size: 1.2em;
        }

        .red-slash {
            color: $red;
        }

        .copyright {
            font-size: $font-size-xs;
            display: block;
            margin-bottom: 5px;
        }
    }
    .social-icons {
        line-height: $footer-height;

        a {
            margin: 0 10px;
        }
    }

    .angled-border {
        border: 1px solid $white;
        position: absolute;
        top: 0;
        bottom: 1px;
        transform: skewX(-20deg);
    }
}

/* ----- col-md ----- */
@media all and (min-width: 992px) and (max-width: 1199px) {

}

/* ----- col-sm ----- */
@media all and (min-width: 768px) and (max-width: 991px) {

    $footer-height: 75px;
    footer#colophon {
        height: $footer-height;

        .site-info {
            height: $footer-height;
        }
        .social-icons {
            line-height: $footer-height;
        }
    }
}

@media screen and (max-width: 768px) {

    body {
        padding-top: 60px;
    }
    $mobile-header-height: 60px;

    header#masthead {
        height: $mobile-header-height;

        #logo {
            height: 40px;
            width: auto;
        }
        .site-branding {
            line-height: $mobile-header-height;
        }
        #site-navigation {
            line-height: $mobile-header-height;
        }
    }

    .more-case-studies {
        background: $white;
        padding: 10px 20px;
        display: block;
        @include skew(-30deg, 0);
        margin: 25px;

        a {
            display: block;
            color: $red;
            font-weight: bold;
            @include skew(30deg, 0);
        }
    }

    $footer-height: auto;
    footer#colophon {
        height: $footer-height;
        text-align: center;

        .site-info {
            height: $footer-height;
            margin-left: 0;

            > span {
                display: block;
            }
            .red-slash {
                display: none;
            }
        }
        .social-icons {
            line-height: $footer-height;
        }
    }
}