#clients {

    .client {
        margin-bottom: 25px;
        margin-top: 25px;

        .client-image {
            background: $gray-light;
            border: 2px solid $white;
            border-radius: $border-radius-base;


            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        .client-title {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    #clients {

        .client {

            .client-title {
                margin-top: 5px;
            }
        }
    }
}