.widget {
  margin: 0 0 1.5em;

  /* Make sure select elements fit in widgets */
  select {
    max-width: 100%;
  }
}

.widget-title {
    font-size: $font-size-h2;
}

/* Search widget */
.widget_search .search-submit {
  display: none;
}

#amazonbook-widget {

    #book {
        max-width: 100%;
        height: auto;
    }
    #amazon-image {
        max-width: 150px;
    }
}

#contact-form-widget {

    form {

        > *:not(:first-child) {
            margin-top: 5px;
        }

        #contact-form-submit {
            background: none;
            border: none;
            color: $red;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.tp_recent_tweets {

     ul {
         padding-left: 0;
     }
 }

#categories-widget, #archives-widget {

    ul {
        list-style-type: none;
        padding: 0;

        li:before {
            font-family: FontAwesome;
            content: "\f105 ";
            padding-right: 5px;
        }
    }
}

@media screen and (max-width: 768px) {
    #amazonbook-widget {
        margin-top: 10px;
    }
}


//Text Widget
#text-widget {
    margin-top: 80px;
    padding: 15px;
    border: 1px solid white;
}