.main-navigation {

    .menu-primary-container {
        float: left;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                float: left;
                position: relative;

                &:hover > a {
                    border-bottom: 2px solid $gray-light;
                }

                //Add slashes after menu links, except for last one
                &:not(:last-child):after {
                    content: '/';
                    color: $red;
                    padding: 0 10px;
                    font-weight: bold;
                }

                a {
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
        }

        .current_page_item a,
        .current-menu-item a, {
            border-bottom: 2px solid $gray-light;
        }
    }
}

/* ---- Footer Menu ---- */
#menu-footer {
    margin: 0;
    padding: 0;
    display: inline;

    li {
        display: inline-block;

        &:after {
            content: '/';
            padding: 0 5px;
            text-decoration: none;
        }
    }
}

/* Small menu */
.menu-toggle {
    display: none;
    z-index: 9999;
}

.site-main .comment-navigation,
.site-main .paging-navigation,
.site-main .post-navigation {
    margin: 0 0 1.5em;
    overflow: hidden;
}

.comment-navigation .nav-previous,
.paging-navigation .nav-previous,
.post-navigation .nav-previous {
    float: left;
    width: 50%;
}

.comment-navigation .nav-next,
.paging-navigation .nav-next,
.post-navigation .nav-next {
    float: right;
    text-align: right;
    width: 50%;
}

@media all and (min-width: 768px) and (max-width: 991px) {
    .main-navigation {

        .menu-primary-container {

            ul {

                li {
                    a {
                        font-size: .75em;
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .menu-toggle,
    .main-navigation.toggled .menu-primary-container {
        display: block;
        text-align: right;
    }

    .menu-toggle {
        position: fixed;
        top: 15px;
        right: 15px;
        font-size: 1.5em;
    }

    .main-navigation ul {
        display: none;
    }

    /* Mobile Menu */
    .main-navigation {

        .menu-primary-container {
            display: none;
            float: none;
            position: fixed;
            top: 60px;
            bottom: 0;
            left: 0;
            right: 0;
            background: $blue;
            z-index: 9999;

            ul {
                display: block;
                list-style: none;
                padding: 25px 0 0 10px;
                margin: 0;

                li {
                    float: none;
                    position: relative;
                    text-align: left;
                    padding-bottom: 10px;

                    &:hover > a {
                        border-bottom: none;
                    }

                    //Add slashes after menu links, except for last one
                    &:not(:last-child):after {
                        content: normal;
                    }
                    &:before {
                        content: '/';
                        color: $red;
                        padding: 0 10px;
                        font-weight: bold;
                    }

                    a {
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                }
            }

            .current_page_item a,
            .current-menu-item a, {
                border-bottom: none;
                color: $red;
            }

            /* ---- Mobile Social Icons ---- */
            .mobile-social-icons {
                text-align: center;
                margin-top: 25px;

                a {
                    text-decoration: none;

                    .fa-stack {
                        font-size: .85em;

                        i:not(:first-child) {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
}