#home-video {
    position: absolute;
    bottom: 0;
    top:90px;
    right: 0;
    left: 0;
    overflow: hidden;
    background: url('../images/Fast-Traffic-Motion-Image.jpg') center center/cover no-repeat;

    video {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    img {

    }

    #video-indicator {
        position: absolute;
        bottom: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 1;
        @include animation(pulsate 2s ease infinite);
    }
}


#video-hack {

}

//Our clients list
.our-clients {

    .our-client {
        font-size: 20px;
    }
}


@-webkit-keyframes pulsate {
    0% {opacity: 1}
    50% {opacity: .25}
    100% {opacity: 1}
}
@keyframes pulsate {
    0% {opacity: 1}
    50% {opacity: .25}
    100% {opacity: 1}
}

@media screen and (max-width: 768px) {
    #home-video {
        top: 60px;
    }
}

/* 1. No object-fit support: */
@media (min-aspect-ratio: 16/9) {
    #home-video > video { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
    #home-video > video { width: 300%; left: -100%; }
}
/* 2. If supporting object-fit, overriding (1): */
@supports (object-fit: cover) {
    #home-video > video {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}