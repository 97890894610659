%image-border {
    border: 2px solid $white;
    border-radius: $border-radius-base;
}

%image-overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparentize($gray-lighter, .5);

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -28.5px;
        margin-left: -28.5px;
    }
}

#featured-case-studies-container {

    .controls {

        a {
            text-decoration: none;
            margin: 0 5px;
        }
    }

    #featured-case-studies {

        .featured-case-study {

            .featured-case-study-image {
                position: relative;
                height: 400px;
                width: 100%;

                .featured-case-study-image-overlay {
                    @extend %image-overlay;
                }

                &:hover .featured-case-study-image-overlay {
                    display: block;
                }
            }

            .featured-title {
                margin-top: 0;
            }
        }
    }
}

#case-studies {

    .case-study {
        margin: 20px 0;


        .case-study-image {
            position: relative;
            height: 250px;
            width: 100%;
            @extend %image-border;


            .case-study-image-overlay {
                @extend %image-overlay;
            }

            &:hover .case-study-image-overlay {
                display: block;
            }
        }

        .case-study-title {

        }
    }
}

/* ---- More case studies button ---- */
.more-case-studies {
    background: $white;
    padding: 10px 20px;
    display: inline-block;
    @include skew(-30deg, 0);
    margin: 25px;
    float: right;

    a {
        display: block;
        color: $red;
        font-weight: bold;
        @include skew(30deg, 0);
    }
}

.featured-case-study-image {
    @extend %image-border;
    width: 100%;
    height: auto;
}

@media screen and (max-width: 768px) {
    #case-studies {

        .case-study {


            .case-study-image {
                height: 150px;

                .case-study-image-overlay {

                }

                &:hover .case-study-image-overlay {

                }
            }

            .case-study-title {

            }
        }
    }

    #featured-case-studies-container {

        .controls {

            a {
                text-decoration: none;
                margin: 0 5px;
            }
        }

        #featured-case-studies {

            .featured-case-study {

                .featured-case-study-image {
                    height: 250px;
                    margin-bottom: 10px;

                    .featured-case-study-image-overlay {

                    }

                    &:hover .featured-case-study-image-overlay {

                    }
                }

                .featured-title {

                }
            }
        }
    }

    /* ---- More case studies button ---- */
    .more-case-studies {
        background: $white;
        padding: 10px 20px;
        display: block;
        width: 100%;
        @include skew(0deg, 0);
        margin: 0;
        text-align: center;

        a {
            display: block;
            color: $red;
            font-weight: bold;
            @include skew(0deg, 0);
        }
    }
}