#case-study-single {

    #case-study-links {

        a {
            margin: 0 5px;
        }
    }

    #case-study-header {
        min-height: 450px;
        position: relative;
        border: 2px solid $white;
        border-radius: $border-radius-base;

        #lead-in-paragraph {
            margin: 0 0 0 65%;
            width: 35%;
            min-height: 450px;
            background: $gray-light;
            padding: 30px;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.3em;
        }
    }
}

@media screen and (max-width: 768px) {
    #case-study-single {

        #case-study-links {

            a {

            }
        }

        #case-study-header {
            background-position: center top !important;
            background-size: contain !important;


            #lead-in-paragraph {
                position: static;
                margin: 0;
                width: 100%;
                min-height: 0;
                height: auto;
                margin-top: 200px;
            }
        }
    }
}